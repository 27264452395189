import React, { Component } from 'react'
import { withRouter } from 'next/router'
import Link from 'next/link'
import { COMMIT_HASH } from '../config'

class Footer extends Component {
  state = {
    theme: 'light',
  }

  componentDidMount = () => {
    this.detectColorScheme()
    window.setTheme = this.setTheme
    window.detectColorScheme = this.detectColorScheme
  }

  detectColorScheme = () => {
    if (typeof window === 'undefined') {
      return
    }

    if (this.props.router.pathname.startsWith('/blog')) return
    if (this.props.router.pathname.startsWith('/login')) return
    if (this.props.router.pathname.startsWith('/pricing')) return
    if (this.props.router.pathname.startsWith('/homepage') || window.location.pathname === '/')
      return

    let theme = 'light' // default to light
    try {
      // determines if the user has a set theme
      // local storage is used to override OS theme settings
      const storedTheme = window.localStorage.getItem('theme')
      if (storedTheme === 'dark') {
        theme = 'dark'
      } else if (storedTheme === 'light' || !window.matchMedia) {
        theme = 'light'
      } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        // OS theme setting detected as dark
        theme = 'dark'
      }

      window.matchMedia('(prefers-color-scheme: dark)').addListener(e => {
        this.saveTheme(e.matches ? 'dark' : 'light')
      })
    } catch (err) {
      console.error('footer detectColorScheme error', err)
    }

    this.setTheme(theme)
  }

  setTheme = theme => {
    this.setState({ theme: theme || (this.state.theme === 'light' ? 'dark' : 'light') })
    // set preferred theme with a `data-theme` attribute
    document.documentElement.setAttribute('data-theme', theme)
  }

  saveTheme = theme => {
    try {
      window.localStorage.setItem('theme', theme)
    } catch (err) {
      console.error('footer saveTheme error', err)
    }
    this.setTheme(theme)
  }

  render() {
    if (
      (this.props.router.pathname === '/' && !this.props.profile) ||
      this.props.router.pathname.startsWith('/blog') ||
      this.props.router.pathname.startsWith('/login') ||
      this.props.router.pathname.startsWith('/pricing') ||
      this.props.router.pathname.startsWith('/homepage') ||
      this.props.router.pathname === '/'
    ) {
      return null
    }

    return (
      <div className="Footer translate">
        <span className="commit-hash">version:&nbsp;{COMMIT_HASH}</span>{' '}
        <div>
          <Link as="/homepage" href="/homepage" className="home">
            home
          </Link>
          <span>&nbsp;|&nbsp;</span>
          <a target="_blank" rel="noreferrer nofollow" href="https://docs.kubesail.com">
            docs
          </a>
          <span>&nbsp;|&nbsp;</span>
          <Link as="/blog" href="/blog" className="blog">
            blog
          </Link>
          <span>&nbsp;|&nbsp;</span>
          <Link as="/support" href="/support" className="privacy">
            help
          </Link>
          <span>&nbsp;|&nbsp;</span>
          <Link as="/privacy" href="/privacy" className="privacy">
            privacy
          </Link>
          <span>&nbsp;|&nbsp;</span>
          <Link as="/terms" href="/terms" className="privacy">
            terms
          </Link>
          <span>&nbsp;|&nbsp;</span>
          <a target="_blank" rel="noreferrer nofollow" href="https://twitter.com/KubeSail/">
            twitter
          </a>
          <span>&nbsp;|&nbsp;</span>
          {this.state.theme === 'dark' ? (
            <a onClick={() => this.saveTheme('light')}>light</a>
          ) : (
            <a onClick={() => this.saveTheme('dark')}>dark</a>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(Footer)
